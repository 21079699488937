import { Link } from 'gatsby'
import kebabCase from 'lodash/kebabCase'

interface NodeItem {
  node: {
    meta_title?: string
    page_name?: string
    page_path?: string
    title?: string
    name?: string
    slug?: string
    url?: string
  }
}

interface SitemapListProps {
  items: NodeItem[]
  link?: string
  pagePath?: boolean
  linkName: keyof NodeItem['node']
  linkKey: keyof NodeItem['node']
}

const SitemapList = ({
  items,
  link = '',
  linkName,
  linkKey,
  pagePath = false,
}: SitemapListProps) => (
  <ul>
    {items.map((item) => (
      <li key={item.node[linkKey]}>
        <Link
          to={`/${link ? `${link}/` : ''}${
            pagePath ? item.node[linkKey] : kebabCase(item.node[linkKey])
          }/`}
        >
          {item.node[linkName]}
        </Link>
      </li>
    ))}
  </ul>
)
export default SitemapList
